import React from 'react'
import { graphql } from 'gatsby'
//
import {
  ContentfulSettings,
  Site,
  ContentfulRecipeConnection,
  ContentfulTechniqueConnection
} from '~generated/gatsby.types'
import { MainLayout } from '~layout'
import { buildPageMetaTags } from '~utils/seo'
import { Hero404 } from '~components/page-404'
import { ContentCarousel } from '~components/page-components'
import theme from '~theme'

type PageQuery = {
  contentfulSettings: ContentfulSettings
  allContentfulRecipe: ContentfulRecipeConnection
  allContentfulTechnique: ContentfulTechniqueConnection
  site: Site
}

type PageProps = {
  data: PageQuery
}

const page404 = {
  __typename: 'Page404',
  metaTags: {
    noindex: true,
    nofollow: true
  }
}

const NotFoundPage = ({ data }: PageProps) => {
  const { site, contentfulSettings, allContentfulRecipe, allContentfulTechnique } = data
  const {
    page404Text,
    page404Image,
    homepage,
    page404ContentBlockTitle,
    page404ContentBlockShortDescription,
    page404ContentBlockCta
  } = contentfulSettings

  const techniquesAndRecipes = [...allContentfulTechnique.edges, ...allContentfulRecipe.edges]
    .map(edge => ({
      ...edge.node
    }))
    .sort((a: any, b: any) => b.publishedDate - a.publishedDate)
    .slice(0, 8)

  const shortDescription = {
    shortDescription: page404ContentBlockShortDescription?.page404ContentBlockShortDescription
  }

  return (
    <MainLayout metaTags={buildPageMetaTags(page404, site)} contentfulSettings={contentfulSettings}>
      <Hero404 image={page404Image} text={page404Text?.page404Text} cta={homepage} />
      <ContentCarousel
        id="404-content-carousel"
        title={page404ContentBlockTitle}
        shortDescription={shortDescription}
        contents={techniquesAndRecipes}
        cta={page404ContentBlockCta}
        bgColorOverride={theme.colors.white}
      />
    </MainLayout>
  )
}

export default NotFoundPage

export const notFoundQuery = graphql`
  query($node_locale: String!) {
    contentfulSettings(locale: { eq: $node_locale }) {
      ...QuerySiteSettings
    }

    allContentfulRecipe(sort: { fields: publishedDate, order: DESC }, limit: 8) {
      edges {
        node {
          __typename
          id
          title
          slug
          publishedDate
          shortDescription {
            shortDescription
          }
          categories {
            title
            id
          }
          image {
            bynder: bynderAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
            local: localAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
          }
        }
      }
    }

    allContentfulTechnique(sort: { fields: publishedDate, order: DESC }, limit: 8) {
      edges {
        node {
          __typename
          id
          title
          slug
          publishedDate
          shortDescription {
            shortDescription
          }
          categories {
            title
            id
          }
          image {
            bynder: bynderAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
            local: localAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
          }
        }
      }
    }

    site {
      ...FragmentSite
    }
  }
`
