import styled from 'styled-components'
//
import { Media } from '~utils'

export const StyledMedia = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  ${Media({
    height: ['95vh', null, null, '610px'],
    maxHeight: ['812px', null, '610px', '610px']
  })}
`

export const StyledMediaMobileInner = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100vw;

  ${Media({
    overflow: ['visible', null, null, 'hidden'],
    display: ['block', 'block', 'none', 'none', 'none']
  })}

  .gatsby-image-wrapper {
    height: 100%;
  }
`

export const StyledMediaDesktopInner = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100vw;

  ${Media({
    overflow: ['visible', null, null, 'hidden'],
    display: ['none', 'none', 'block', 'block', 'block']
  })}

  .gatsby-image-wrapper {
    height: 100%;
  }
`

export const StyledContents = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding-left: 24px;
  padding-right: 24px;
`

export const StyledContentsInner = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  margin: 0 auto;

  ${Media({
    maxWidth: ['100%', null, '400px', '700px'],
    marginTop: ['auto', null, '200px', '100px'],
    marginBottom: ['50px', null, null, null]
  })};

  h4 {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: 2px;
    color: #333333;
    text-transform: uppercase;
  }

  h1 {
    ${Media({
      fontSize: ['40px', null, null, '60px'],
      lineHeight: [1.25, null, null, 1.2],
      letterSpacing: ['0.67px', null, null, '1px']
    })}
    margin-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    color: ${props => props.theme.colors.primary};
  }

  button {
    min-width: 260px;
    margin: 0 auto;
  }
`
