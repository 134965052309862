import React from 'react'
import { IntlContext } from 'react-intl'
//
import { ContentfulImage } from '~generated/gatsby.types'
import {
  StyledMedia,
  StyledContents,
  StyledContentsInner,
  StyledMediaMobileInner,
  StyledMediaDesktopInner
} from './Styled'
import { getSourceImage, ImageFluid, ImageMobileFluid } from '~utils/image'
import CTA from '~components/base-components/cta'

type Props = {
  image: ContentfulImage | null | undefined
  text?: string | null | undefined
  cta?: any
}

const Hero404 = ({ image, text, cta }: Props) => {
  const parsedImage = getSourceImage(image)
  const intl: any = React.useContext(IntlContext)

  return (
    <StyledMedia>
      <StyledContents>
        <StyledContentsInner>
          <h4>{intl.formatMessage({ id: 'page404.prefix' })}</h4>
          <h1>{text}</h1>
          <CTA
            theme="black"
            fullWidth
            content={cta}
            title={intl.formatMessage({ id: 'page404.cta' })}
            ariaLabel={intl.formatMessage({ id: 'a11y.cta.homepage' })}
          />
        </StyledContentsInner>
      </StyledContents>
      <StyledMediaMobileInner>{parsedImage && <ImageMobileFluid image={parsedImage} />}</StyledMediaMobileInner>
      <StyledMediaDesktopInner>{parsedImage && <ImageFluid image={parsedImage} />}</StyledMediaDesktopInner>
    </StyledMedia>
  )
}

export default Hero404
